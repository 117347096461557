<template>
  <div class="main-content">
    <base-bread-crumbs/>
    <div class="main-content__inner">
      <div class="info-frame">
        <img class="info-frame__graphics" src="@/assets/confirmation.svg" />
        <div class="info-frame__content">
          <div class="info-frame__greeting text-32-800" v-text="$t('successTitle')"/>
          <div class="info-frame__text">
            <p>{{ $t('successConfirm') }}</p>
            <p>
              {{ $t('successWeSent') }}
              <b v-if="newUserEmail.length > 0">{{newUserEmail}}</b>
              <b v-else>{{ $t('emailSample') }}</b>
            </p>
          </div>
          <div class="info-frame__small-text" v-text="$t('successSmallText')"></div>
        </div>
        <div class="button-container">
          <simple-button
            :value="$t('toLogin')"
            @click="$router.push('/')"
          />
          <simple-button
            :value="$t('resend')"
            transparent
            :style="{ width: '150px'}"
            :disabled="newUserEmail.length === 0"
            @click="() => { $store.dispatch('resendConfirmEmail', this.newUserEmail) }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBreadCrumbs from '@/components/ui/layout/BaseBreadCrumbs.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Success',
  components: {
    BaseBreadCrumbs,
  },
  computed: {
    ...mapGetters([
      'newUserEmail',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}
</style>
